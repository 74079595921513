import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  documentDate: {
    title: 'Дата',
    type: 'date',
    slot: 'date',
  },
  lotId: {
    title: 'Поставка',
    type: 'lot',
    slot: 'lot',
  },
  locationId: {
    title: 'Склад',
    type: 'location',
    slot: 'location',
  },
};

export default fields;
