import { Api } from './Api';
import { Income, Incomes } from '../models/Income';
import CustomError from '../models/CustomError';

export class IncomeApi extends Api {
  async fetchAll(page: number, pageSize: number): Promise<Incomes> {
    let incomes: Income[] = [];
    try {
      const result = await this.fetchAllProtected<Incomes>('docs/inbound', {
        params: { page, pageSize },
      });
      if (result?.results?.length > 0) {
        incomes = Income.createFromArray(Income, result.results);
      }
      return {
        ...result,
        results: [...incomes],
      };
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Income> {
    try {
      const result = await this.fetchByIdProtected<Income>('docs/inbound', id);
      if (result) {
        return Income.createFromObject(Income, result);
      }
      return new Income({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Income): Promise<Income> {
    try {
      const result = await this.sendProtected<Income>('docs/inbound', data);
      return Income.createFromObject(Income, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Income): Promise<Income> {
    try {
      const result = await this.updateProtected('docs/inbound', data);
      return Income.createFromObject(Income, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('docs/inbound', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async post(id: number): Promise<Income> {
    try {
      const result = await this.postProtected<Income>('docs/inbound', id);
      if (result) {
        return Income.createFromObject(Income, result);
      }
      return new Income({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async unPost(id: number): Promise<Income> {
    try {
      const result = await this.unPostProtected<Income>('docs/inbound', id);
      if (result) {
        return Income.createFromObject(Income, result);
      }
      return new Income({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
