
import { defineComponent, onMounted, reactive, ref } from 'vue';

import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ContextMenu from 'primevue/contextmenu';

import { Income } from '@/classes/models/Income';
import { IncomeApi } from '@/classes/api/IncomeApi';
import CustomError from '@/classes/models/CustomError';
import { dateFilter } from '@/utils/filters';
import DOCUMENT_STATUS from '@/enums/document-status';

import IncomeForm from './IncomeForm.vue';

export default defineComponent({
  name: 'IncomeCatalog',
  components: {
    'income-form': IncomeForm,
    DataTable,
    Column,
    Button,
    ContextMenu,
  },

  setup() {
    const incomeDialog = ref(false);
    const selectedIncome = ref<Income>(new Income({}));
    const selectedIncomes = ref<Income[]>([]);
    const item = reactive<Income>(new Income({}));
    const incomes = ref<Income[]>([]);
    const loading = ref(true);
    const editMode = ref(false);
    // eslint-disable-next-line
    const ctxMenu = ref<any>(null);

    const pages = ref(0);
    const page = ref(0);
    const size = ref(200);
    const totalRecords = ref(0);
    const loadedRecords = ref(0);

    const confirm = useConfirm();
    const toast = useToast();
    const api = new IncomeApi();
    const headerStyle = 'width: 15rem';

    const update = async () => {
      loading.value = true;
      page.value = 0;
      try {
        const result = await api.fetchAll(page.value + 1, size.value);
        incomes.value = result.results || [];
        totalRecords.value = result.rowCount || 0;
        loadedRecords.value = result.results ? result.results.length : 0;
        pages.value = result.pageCount || 0;
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    onMounted(() => {
      update();
    });

    const isPosted = (status: DOCUMENT_STATUS): boolean => {
      return status === DOCUMENT_STATUS.POSTED;
    };

    const getFilteredDate = (date: string): string => {
      return dateFilter(date, 'datetime');
    };

    // eslint-disable-next-line
    const onRowContextMenu = (event: any) => {
      ctxMenu.value.show(event.originalEvent);
    };

    const openNew = () => {
      editMode.value = false;
      incomeDialog.value = true;
    };

    const edit = (selected: Income) => {
      item.replaceBy(selected);
      editMode.value = true;
      incomeDialog.value = true;
    };

    const confirmDeleteSelected = () => {
      confirm.require({
        message: 'Действительно хотите удалить выбранное?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            for (const item of selectedIncomes.value) {
              if (item.id) {
                await api.remove(item.id);
              }
            }
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Удалено',
              life: 3000,
            });
            await update();
          } catch (error) {
            if (error instanceof CustomError) {
              error.show('error', 5000, toast);
            } else {
              console.log(error);
            }
          }
        },
      });
    };

    const confirmDelete = (itm: Income) => {
      confirm.require({
        message: 'Действительно хотите удалить?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            itm.id && (await api.remove(itm.id));
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Удалено',
              life: 3000,
            });
            await update();
          } catch (error) {
            if (error instanceof CustomError) {
              error.show('error', 5000, toast);
            } else {
              console.log(error);
            }
          }
        },
      });
    };

    const contextMenu = [
      {
        label: 'Открыть',
        icon: 'pi pi-fw pi-search',
        command: () => {
          edit(selectedIncome.value);
        },
      },
      {
        label: 'Удалить',
        icon: 'pi pi-fw pi-times',
        command: () => {
          confirmDelete(selectedIncome.value);
        },
      },
    ];

    const loadMore = async () => {
      if (page.value >= pages.value) {
        return;
      }

      loading.value = true;
      try {
        page.value += 1;
        const result = await api.fetchAll(page.value + 1, size.value);
        if (result.results) {
          incomes.value = [...incomes.value, ...result.results];
          loadedRecords.value += result.results.length;
        }
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    return {
      page,
      pages,
      loadedRecords,
      totalRecords,
      ctxMenu,
      contextMenu,
      incomes,
      selectedIncomes,
      selectedIncome,
      onRowContextMenu,
      loading,
      openNew,
      confirmDeleteSelected,
      headerStyle,
      incomeDialog,
      item,
      editMode,
      update,
      getFilteredDate,
      isPosted,
      loadMore
    };
  },
});
