<template>
  <div class="root">
    <Card style="width: 100%">
      <template #content>
        <income-catalog />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';

import IncomeCatalog from '@/components/Incomes/IncomeCatalog.vue';

export default {
  components: {
    Card,
    'income-catalog': IncomeCatalog,
  },
};
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
  height: calc(100vh - 8rem);
}
</style>
