<template>
  <div class="p-formgrid p-grid p-inputtext-sm" style="font-size: 1rem">
    <div class="p-col-6">
      <slot name="date"></slot>
    </div>
    <div class="p-col-6"></div>
    <div class="p-col-6">
      <slot name="location"></slot>
    </div>
    <div class="p-col-6">
      <slot name="lot"></slot>
    </div>
    <div class="p-col-12">
      <slot name="lines"></slot>
    </div>
    <div class="p-col-12">
      <slot name="default"></slot>
    </div>
    <Divider />
    <div class="p-col-9"></div>
    <div class="p-col-3">
      <slot name="total"></slot>
    </div>
  </div>
</template>

<script>
import Divider from 'primevue/divider';

export default {
  name: 'IncomeFormLayout',
  components: {
    Divider,
  },
};
</script>
